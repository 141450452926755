<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="main-navbar">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.png" alt="image">
                    </router-link>

                    <div 
                        class="navbar-toggler"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">               
                                    Accueil
                                </router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link to="/about" class="nav-link">
                                    Qui sommes-nous ? 
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link to="/features" class="nav-link">
                                    EXPERTISES 
                                </router-link>
                            </li> -->
<!-- 
                            <li class="nav-item">
                                <router-link to="/services" class="nav-link">
                                    Solutions 
                                </router-link>
                            </li> -->
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">
                                    Contact 
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data(){
        return {
            isSticky: false,
            isSearch: false,
            isDot:false,
            active: false,
            button_active_state: false
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },

    methods: {
        isSearchMethod(isSearch){
            console.log(this.isSearch)
            return this.isSearch = !isSearch
        }
    }
}
</script>