<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title text-width">
                <span>Nos Capacités</span>
                <p>En mettant l'accent sur les solutions technologiques Web et mobiles, nous pouvons guider votre équipe à travers les types d'initiatives suivants</p>
                <div class="bar"></div>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="flaticon-promotion"></i>
                        </div>
                        <h3>
                            Développement Web
                        </h3>
                        <p>
                            Bénéficiez de notre expertise digitale et de notre expérience en développement web.
                            Nous sommes là pour planifier, développer et maintenir tous vos projets digitaux !
                        </p>

                        <div class="number">
                            <span>1</span>
                        </div>
            
                        <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="flaticon-technical-support"></i>
                        </div>
                        <h3>
                            Conseil, expertise
                        </h3>
                        <p>L'expertise de Aksyr en matière de logiciels open source garantit une exécution transparente de projets vitaux et un accès aux meilleurs talents pour vos équipes internes.</p>

                        <div class="number">
                            <span>2</span>
                        </div>
            
                        <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="flaticon-digital-marketing"></i>
                        </div>
                        <h3>
                            UX Design
                        </h3>
                        <p>
                            Une belle conception UX qui favorise l'engagement.
                            Notre équipe s'assure que chaque élément graphique communique efficacement votre message.
                        </p>


                        <div class="number">
                            <span>3</span>
                        </div>
            
                        <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-features">
                        <div class="icon">
                            <i class="flaticon-network"></i>
                        </div>
                        <h3>
                            AI / ML
                        </h3>
                        <p>
                            L'automatisation des tâches répétitives est essentielle pour optimiser votre efficacité opérationnelle.
                            Grâce à notre expertise en intelligence artificielle
                        </p>
                        <div class="number">
                            <span>4</span>
                        </div>
            
                        <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CreateAwesomeService',
}
</script>