<template>
    <div>
        <Navbar />
        <PageBanner />
        <ContactContent />
        <GetOnlyNewUpdate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageBanner from '../Contact/PageBanner'
import ContactContent from '../Contact/ContactContent'
import Footer from '../Layout/Footer'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageBanner,
        ContactContent,
        Footer,
    }
}
</script>
