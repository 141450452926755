<template>
    <div class="main-banner">
        <div class="main-banner-item">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="main-banner-content">
                                    <h3>Votre Agence Web</h3>
                                    <h1>Aksyr DIGITAL</h1>  
                                    <p>
                                        Notre mission est d’accompagner les entreprises, les entrepreneurs et les organisations à se démarquer en ligne. 
                                        De la conception graphique au développement technique, nous allions créativité, expertise et écoute pour faciliter votre transformation digitale.
                                    </p>
                                    <strong>
                                        Ensemble, donnons vie à votre vision digitale.
                                    </strong>
                                    <div class="banner-btn">
                                        <router-link to="/contact" class="default-btn">
                                            Prenez le contrôle de votre avenir
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="main-banner-image">
                                    <img src="../../assets/images/banner/banner-image-1.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="main-banner-shape">
            <div class="shape-1">
                <img src="../../assets/images/banner/banner-shape-1.png" alt="image">
            </div>
            <div class="shape-2">
                <img src="../../assets/images/banner/banner-shape-2.png" alt="image">
            </div>
            <div class="shape-3">
                <img src="../../assets/images/banner/banner-shape-3.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner',
}
</script>