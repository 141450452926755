<template>
    <div class="services-area bg-fff5ee pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span>Technologies</span>
                <p>Nous refusons d’être généralistes et nous concentrons sur les piles technologiques que nous connaissons le mieux.</p>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/symfony.png" alt="image">
                        </div>
                        <h3>
                            Besoin d'une application web Symfony ou d'une solution personnalisée ?
                        </h3>
                        <p>
                            Bénéficiez de la vaste expérience de nos développeurs Symfony. En tant qu'agence web dédiée, nous sommes à votre disposition pour vous proposer nos conseils et notre soutien, 
                            pour des projets de toutes tailles.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/laravel.png" alt="image">
                        </div>
                        <h3>
                            Avez-vous besoin d'une application web ou d'une solution personnalisée complexe ?
                        </h3>
                        <p>Bénéficiez de la longue expérience de nos développeurs. En tant qu’agence web, nous vous conseillons et vous accompagnons avec plaisir, quelle que soit la taille de vos projets.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/sylius.png" alt="image">
                        </div>
                        <h3>
                            Améliorez votre expérience commerciale
                        </h3>
                        <p>
                            Grâce à notre sens des affaires, nous traduisons les objectifs en solutions. 
                            Bénéficier d'une équipe exceptionnelle dotée d'une expertise technique approfondie, d'une flexibilité et d'une expérience avérée dans le travail avec les plateformes de E-commerce.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/vuejs.png" alt="image">
                        </div>
                        <h3>
                            Avez-vous besoin d'un framework évolutif comme Vue.js pour votre projet digital ?
                        </h3>
                        <p>
                            Profitez de la vaste expérience de nos développeurs avec Vue.js. En tant qu'agence web, nous sommes prêts à vous fournir des conseils et une assistance, quelle que soit l'ampleur de votre projet.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/react.png" alt="image">
                        </div>
                        <h3>
                            Avez-vous besoin d’une interface utilisateur dynamique ou d’une application monopage évolutive ?
                        </h3>
                        <p>
                            Profitez de la vaste expérience de nos développeurs avec React. En tant qu'agence web, nous sommes là pour vous offrir des conseils et un soutien pour des projets de toute envergure.
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-services">
                        <div class="image">
                            <img src="../../assets/images/services/devops.png" alt="image">
                        </div>
                        <h3>
                            Accélérer et sécuriser votre transformation digitale
                        </h3>
                        <p>
                            Avez-vous besoin d’une équipe DevOps avec une spécialisation approfondie dans les déploiements Symfony, Laravel, Node.js ou Python ? 
                            Si vous souhaitez créer sans effort des environnements stables, sécurisés et toujours accessibles, tout en réduisant les charges opérationnelles. vous avez trouvé la bonne équipe.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape1">
                <img src="../../assets/images/default-shape/default-shape1.png" alt="image">
            </div>
            <div class="shape2">
                <img src="../../assets/images/default-shape/default-shape2.png" alt="image">
            </div>
            <div class="shape3">
                <img src="../../assets/images/default-shape/default-shape3.png" alt="image">
            </div>
            <div class="shape4">
                <img src="../../assets/images/default-shape/default-shape4.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurDigitalMarketing',
}
</script>