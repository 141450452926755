<template>
    <div>
        <Navbar />
        <MainBanner />
        <CreateAwesomeService />
        <WeHelpCompanies />
        <OurDigitalMarketing />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../Home/MainBanner'
import CreateAwesomeService from '../Home/CreateAwesomeService'
import WeHelpCompanies from '../Home/WeHelpCompanies'
import OurDigitalMarketing from '../Home/OurDigitalMarketing'
import Footer from '../Layout/Footer'

export default {
    name: 'HomePage',
    components: {
        Navbar,
        MainBanner,
        CreateAwesomeService,
        WeHelpCompanies,
        OurDigitalMarketing,
        Footer,
    }
}
</script>
