<template>
    <div class="about-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="about-image">
                        <img src="../../assets/images/about/about-1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-content">
                        <span>Qui sommes-nous ?</span>
                        <div class="bar"></div>
                        <strong> 
                            Nous sommes une équipe passionnée par le digital et spécialisée dans la création de sites web sur mesure.
                        </strong>
                        
                        <p>
                          Chez Aksyr DIGITAL, nous mettons notre savoir-faire au service de votre transformation digitale.
                        </p>

                        <p>
                            Grâce à une approche innovante et à l’intégration des technologies de l’intelligence artificielle (IA), nous offrons des solutions complètes pour répondre à vos besoins 
                        </p>
                        <div class="about-btn">
                            <router-link to="/contact" class="default-btn">
                                Prenez le contrôle de votre avenir
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WeHelpCompanies',
}
</script>