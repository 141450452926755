import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePage from "../components/Pages/HomePage";
import ContactPage from "../components/Pages/ContactPage";

const routes = [
  { path: "/", component: HomePage },
  { path: "/contact", component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
